/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice } from '@reduxjs/toolkit';
import { getPrice, getOurReport, getLedger, getDashboard } from './overviewMiddleware';
import { type ourReport } from './overviewTypes';
// import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

interface InitialState {
  goldPrice: number;
  silverPrice: number;
  ourReport: ourReport[];
  dashboard: any;
}

const initialState: InitialState = {
  goldPrice: 0,
  silverPrice: 0,
  ourReport: [],
  dashboard: {}
};

type FlattenedObject = Record<string, any>;

const keysToStringify = ['loanPayments', 'pledgePayments'];

const flattenObject = (obj: any, prefix: string = ''): FlattenedObject =>
  Object.keys(obj).reduce<FlattenedObject>((acc: FlattenedObject, key: string) => {
    const pre = prefix.length ? `${prefix}.` : '';
    
    if (keysToStringify.includes(key) && Array.isArray(obj[key])) {
      acc[pre + key] = JSON.stringify(obj[key]);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }

    return acc;
  }, {});

const flattenLedgerData = (ledgerData:any) => {
  return ledgerData.map((entry:any) => flattenObject(entry));
};

// const generateExcel = (ledgerData: any) => {

//   const flattenedData = flattenLedgerData(ledgerData.ledger);
  
//   const worksheet = XLSX.utils.json_to_sheet(flattenedData);

//   const flattenedBankData = flattenLedgerData(ledgerData.bank);
//   const bankWorksheet = XLSX.utils.json_to_sheet(flattenedBankData);

//   const workbook = XLSX.utils.book_new();

//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Ledger');

//   XLSX.utils.book_append_sheet(workbook, bankWorksheet, 'Bank');

//   const excelBuffer = XLSX.write(workbook, {
//     bookType: 'xlsx',
//     type: 'array'
//   });

//   const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

//   const link = document.createElement('a');
//   link.href = URL.createObjectURL(blob);
//   link.download = 'ledger.xlsx';
//   link.click();
// };

const generateNewExcel = async (ledgerData: any) => {
  const workbook = new ExcelJS.Workbook();

  // Create Ledger Worksheet
  const ledgerSheet = workbook.addWorksheet('Ledger');
  const flattenedData = flattenLedgerData(ledgerData.ledger);
  // const headers = Object.keys(flattenedData[2]);

  const headers = ['Serial Number', 'LOAN START DATE', 'LOAN NO', 'CUSTOMER NAME', 'TYPE', 'TAKE OVER AMOUNT', 'LOAN AMOUNT', 'DC', 'SC', 'NO OF JEWEL', 'Gross Weight', 'Net Weight', 'RATE/GRAM', 'TODAY METAL PRICE', 'LOAN SCHEME', 'ROI', 'NO OF DAYS', 'PENDING INTEREST', 'RECEIVED INTEREST', 'DATE OF PAYMENT', 'RECEIVED PRINCIPAL', 'BALANCE PRINCIPAL', 'TOTAL CLOSURE AMOUNT', 'GOLD PACKET STATUS', 'OTHER BANK NAME', 'OB LOAN DATE', 'OB LOAN NO', 'OB A/C NAME', 'WT', 'OB LOAN AMOUNT', 'OB RATE/GRAM', 'OB CHARGES', 'TENURE', 'OB ROI', 'OB RENEWAL DATE', 'PLEDGE NO OF DAYS', 'REMARKS', 'OB PENDING INTEREST', 'OB INTEREST PAID', 'LOCK AMOUNT MORE/LESS']

  ledgerSheet.columns = headers.map((header) => ({
    header,
    key: header,
    width: header.length + 5,
  }));

  flattenedData.forEach((row:any) => ledgerSheet.addRow(Object.values(row))); 

  ledgerSheet.eachRow((row, rowIndex) => {
    row.eachCell((cell) => {
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      if (rowIndex === 1) {
        cell.font = { bold: true };
      }
    });
  });
  
  const kgfRateColIndex = headers.indexOf('RATE/GRAM') + 1;
const metalPriceColIndex = headers.indexOf('TODAY METAL PRICE') + 1;

if (kgfRateColIndex > 0 && metalPriceColIndex > 0) {
  ledgerSheet.eachRow((row, rowIndex) => {
    if (rowIndex > 1) { // Skip the header row
      const kgfRateCell = row.getCell(kgfRateColIndex);
      const metalPriceCell = row.getCell(metalPriceColIndex);

      const kgfRate = parseFloat(kgfRateCell.value as string);
      const metalPrice = parseFloat(metalPriceCell.value as string);

      if (!isNaN(kgfRate) && !isNaN(metalPrice)) {
        const fillColor = kgfRate < metalPrice ? 'FFC6EFCE' : 'FFFFC7CE'; // Green if greater, red if not
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          };
        });
      }
    }
  });
}


  // Create Bank Worksheet
  const bankSheet = workbook.addWorksheet('Bank');
  const flattenedBankData = flattenLedgerData(ledgerData.bank);
  const bankHeaders = Object.keys(flattenedBankData[0]);

  // Add Headers with Capitalization and Bold Font
  const capitalizedHeaders = bankHeaders.map(header => header.toUpperCase());
  bankSheet.addRow(capitalizedHeaders).eachCell((cell) => {
    cell.font = { bold: true }; // Make headers bold
    cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center-align headers
  });

  // Set Fixed Width for All Columns
  bankSheet.columns = bankHeaders.map(() => ({
    width: 20, // Fixed width for all columns
  }));

  // Add Rows
  flattenedBankData.forEach((row: any) => bankSheet.addRow(Object.values(row)));

  
  // Calculate the Total for 'TOTALPRINCIPAL' column and add it as a new row
  const totalPrincipal = flattenedBankData.reduce((sum: number, row: any) => {
    return sum + (parseFloat(row.totalPrincipal) || 0);
  }, 0);

  // Add Total Row
  const totalRow = Array(bankHeaders.length).fill(''); 
  totalRow[0] = `TOTAL: ${totalPrincipal} `; 
  
  bankSheet.addRow(totalRow).eachCell((cell) => {
    cell.font = { bold: true }; // Make total row bold
    cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center-align total row
  });

  // Save as Blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'ledger.xlsx';
  link.click();
};

const overviewReducer = createSlice({
  name: 'overviewReducer',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPrice.fulfilled, (state, action) => {
      const goldPrice = action.payload.filter(
        (x: any) => x.name === 'Gold'
      )[0];
      const silverPrice = action.payload.filter(
        (x: any) => x.name === 'Silver'
      )[0];
      state.goldPrice = goldPrice? goldPrice.price : 0;
      state.silverPrice = silverPrice? silverPrice.price : 0;
    });

    builder.addCase(getOurReport.fulfilled, (state, action) => {
      state.ourReport = action.payload.report;
    });

    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload;
    });

    builder.addCase(getLedger.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void generateNewExcel(action.payload);
    });
  },
});

export default overviewReducer.reducer;
