import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  getUndisbursedLoansAction,
  updateUndisbursedLoanAction,
} from '../../Loan/store/loanActions';
import { type Loan } from '../../Customer/store/customerTypes';

export default function CashDisbursementsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { undisbursedLoans }: any = useSelector<any>((state) => state?.loan);

  const [disabled, setDisabled] = useState(false);

  const getLoans = async (): Promise<void> => {
    await getUndisbursedLoansAction(dispatch, {});
  };

  useEffect(() => {
    void getLoans();
  }, []);

  const handleUpdate = async (loan: Loan) => {
    setDisabled(true);
    await updateUndisbursedLoanAction(dispatch, { id: loan._id });
    void getLoans();
    setDisabled(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: Loan, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: Loan, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (_: any, record: Loan, index: number) => (
        <img src={record.customer?.photo} style={{ height: 130, width: 130 }} />
      ),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: any, record: Loan, index: number) => (
        <p>{record.principal}</p>
      ),
    },
    {
      title: 'Customer charges',
      dataIndex: 'documentCharge',
      key: 'documentCharge',
      render: (_: any, record: Loan, index: number) => (
        <p>{record.documentCharge}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 200,
      render: (_: any, record: Loan, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              void handleUpdate(record);
            }
          }}
        >
          Cash Disbursed
        </p>
      ),
    },
  ];

  return (
    <div className="users__table__page">
      {!(undisbursedLoans.length > 0) && <Spin spinning={false} fullscreen />}
      <div style={{ width: '100%' }}>
        <Table dataSource={undisbursedLoans} columns={columns} />
      </div>
    </div>
  );
}
