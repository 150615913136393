import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import {
  createNewLoan,
  getLoanTypes,
  searchLoanNumbers,
  getLoanByLoanNumber,
  getUnpledgedLoans,
  getUndisbursedLoans,
  updateUndisbursedLoan,
  getUnapprovedLoans,
  updateUnapprovedLoan,
  searchClosedLoanNumbers,
  getUnsettledPayments,
  updateUnsettledPayment,
  getRecoveryLoans
} from './loanMiddleware';
import { type CreateLoanRequest } from './loanTypes';
// import moment from 'moment';

export const createNewLoanAction = async (
  dispatch: AppDispatch,
  payload: CreateLoanRequest
) => {
  const data = {
    ...payload,
  };
  const res: any = await dispatch(createNewLoan(data));

  
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Loan Created Successfully'));
    
    return { ...res.payload.loan,interestTypePercentage: res.payload.loanPercentage };
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating loan'));
    return false;
  }
};

export const getLoanTypesAction = async (
  dispatch: AppDispatch
) => {
  const res = await dispatch(getLoanTypes({}));
  console.log('res.data', res);
};

export const searchLoanNumbersAction = async (
  dispatch: AppDispatch,
  payload: string
) => {
  const res: any = await dispatch(searchLoanNumbers(payload));

  if (res.meta.requestStatus === 'fulfilled') {
    return res.payload;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error fetching loan numbers'));
    return false;
  }
};

export const searchClosedLoanNumbersAction = async (
  dispatch: AppDispatch,
  payload: string
) => {
  const res: any = await dispatch(searchClosedLoanNumbers(payload));

  if (res.meta.requestStatus === 'fulfilled') {
    return res.payload;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error fetching loan numbers'));
    return false;
  }
};

export const getLoanByLoanNumberAction = async (
  dispatch: AppDispatch,
  payload: string
) => {
  const res: any = await dispatch(getLoanByLoanNumber(payload));

  if (res.meta.requestStatus === 'fulfilled') {
    return res.payload;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error fetching loan numbers'));
    return false;
  }
};

export const getUnpledgedLoansAction = async (
  dispatch: AppDispatch,
  payload: { branch: string}
) => {
  const res = await dispatch(getUnpledgedLoans(payload));
  console.log(res);
};

export const getUndisbursedLoansAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getUndisbursedLoans(null));
  console.log(res);
};

export const updateUndisbursedLoanAction = async (
  dispatch: AppDispatch,
  payload: {id: string}
) => {
  const data = {
    ...payload,
  };
  const res: any = await dispatch(updateUndisbursedLoan(data));

  
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Loan updated Successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error updating loan'));
    return false;
  }
};

export const getUnsettledPaymentsAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getUnsettledPayments(null));
  console.log(res);
};

export const updateUnsettledPaymentsAction = async (
  dispatch: AppDispatch,
  payload: {id: string, status: string}
) => {
  const data = {
    ...payload,
  };
  const res: any = await dispatch(updateUnsettledPayment(data));

  
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Loan updated Successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error updating loan'));
    return false;
  }
};

export const getUnapprovedLoansAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getUnapprovedLoans(null));
  console.log(res);
};

export const getRecoveryLoansAction = async (
  dispatch: AppDispatch,
  payload: {branch: string, minDays: number | null, maxDays: number | null}
) => {
  const res = await dispatch(getRecoveryLoans(payload));
  console.log(res);
};

export const updateUnapprovedLoanAction = async (
  dispatch: AppDispatch,
  payload: {id: string, status: string}
) => {
  const data = {
    ...payload,
  };
  const res: any = await dispatch(updateUnapprovedLoan(data));

  
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Loan updated Successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error updating loan'));
    return false;
  }
};